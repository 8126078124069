import React, { useContext, useState } from "react";
import { Link } from "gatsby"

import "./NavStatic.scss";

import Logo from "../../images/logo-04.png";
import classNames from "classnames";

const NavStatic = () => {
  const [userDropdown, setUserDropdown] = useState(false);
  const [mobileDropdown, setMobileDropdown] = useState(false);



  const onToggleUserDropdown = () => {
    if (userDropdown) {
      setUserDropdown(false);
    } else {
      setUserDropdown(true);
    }
  };

  const onToggleMobileDropdown = (clickSource) => {
    if (clickSource === "logo") {
      setMobileDropdown(false);
    } else {
      mobileDropdown ? setMobileDropdown(false) : setMobileDropdown(true);
    } 
  };

  let userDropdownClass = classNames({
    "navbar-user_dropdown ": userDropdown,
    "display_none ": !userDropdown,
  });

  let mobileDropdownClass = classNames({
    "navbar-mobile_optionsContainer ": mobileDropdown,
    "display_none ": !mobileDropdown,
  });
  let navOptions;
  if (true) {
    navOptions = (
      <div>
        <div className="navbar-text_desktop">
          {/* <Link className="navbar-text" to="/shop/">
           For Shoppers
          </Link>
          <Link className="navbar-text " to="/app/login/">
            Log In
          </Link>
          <Link className="navbar-text navbar-text_highlight" to="/app/signup/">
            Sign up
          </Link> */}
            <Link className="navbar-text" to="/pricing/">
           Pricing
          </Link>
        </div>
        <div className="navbar-text_mobile">
        <Link className="navbar-text" to="/pricing/">
           Pricing
          </Link>
          {/* <button
            onClick={() => onToggleMobileDropdown()}
            className="navbar-text_mobileMenu"
          >
            Menu
          </button> */}
        </div>
      </div>
    );
  } 

  return (
    <div className="navbar-container">
      <Link
        className="navbar-logo_container"
        to="/"
        onClick={() => onToggleMobileDropdown("logo")}
      >
        <img src={Logo} alt="logo" className="navbar-logo" />
        <h1 className="navbar-logo_text">Sizify</h1>
      </Link>

      <div className="navbar-option_container">{navOptions}</div>
      <div
        className={mobileDropdownClass}
        onClick={() => onToggleMobileDropdown()}
      >
 
        <Link className="navbar-mobile_options" to="/app/size-calculator-main/">
          Get Your Size
        </Link>
        <Link className="navbar-mobile_options" to="/shop/">
            For Shoppers
          </Link>
        <Link className="navbar-mobile_options " to="/app/login/">
          Log In
        </Link>
        <Link
          className="navbar-mobile_options navbar-mobile_optionsHighlight"
          to="/app/signup/"
        >
          Sign up
        </Link>
      </div>
    </div>
  );
};

export default NavStatic;
